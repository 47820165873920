.App {
  text-align: center;
  width: 35%;
}
.rightSide{
  width: 65%;
  height: 100vh;
  position: sticky;
  top: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 12px;
  box-shadow: 0px 3px 12px 0px rgba(194,194,194,1);
  -webkit-box-shadow: 0px 3px 12px 0px rgba(194,194,194,1);
  -moz-box-shadow: 0px 3px 12px 0px rgba(194,194,194,1);
  position: sticky;
  top: 0;
  background-color: white;
  /* margin: 0 0 16px 0; */
  z-index: 99;
  min-height: 51px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 900px) {
  .App {
    width: 100%;
  }
  .rightSide{
    display: none;
  }
}